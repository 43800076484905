import React, { createContext } from "react";
import { SERVER_BASE_API_URL, SERVER_BASE_URL } from "../config/serverConfig";
import { WebSocketContext } from "./WSContext";
const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [authPending, setAuthPending] = React.useState(false);
  const { isConnected } = React.useContext(WebSocketContext);

  const checkAdminSession = () => {
    const oneHour = 60 * 60 * 1000;
    const sessionData = JSON.parse(localStorage.getItem("adminSession"));
    if (!sessionData) {
      return false;
    }

    const currentTime = new Date().getTime();
    const sessionAge = currentTime - sessionData.timeStamp;

    if (sessionAge > oneHour) {
      localStorage.removeItem("adminSession");
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    setIsAuthenticated(checkAdminSession());
  }, []);

  React.useEffect(() => {
    if (isConnected)
      if (!isAuthenticated && authPending) {
        handleAuthentication?.();
      }
    return () => { };
  }, [isAuthenticated, authPending, isConnected]);

  const tryToAuth = React.useCallback(() => {
    setAuthPending(true);
  }, []);

  const saveAdmission = () => {
    const currentTime = new Date().getTime();
    const sessionData = {
      token: "admin-session-token",
      timeStamp: currentTime,
    };
    localStorage.setItem("adminSession", JSON.stringify(sessionData));
  };

  const handleAuthentication = async () => {
    if (checkAdminSession()) {
      return;
    }

    const username = prompt("Enter username:");
    const password = prompt("Enter password:");

    if (!username || !password) {
      alert("Username and password cannot be empty.");
      return;
    }

    try {
      const response = await fetch(`${SERVER_BASE_URL}/admin`, {
        headers: {
          Authorization: "Basic " + btoa(username + ":" + password),
        },
      });
      if (response.ok) {
        setIsAuthenticated(true);
        saveAdmission();
      } else {
        alert("Unauthorized");
        setIsAuthenticated(false);
      }
      setAuthPending(false);
    } catch (error) {
      alert("Failed to authenticate. Please try again.");
    } finally {
      setAuthPending(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, authPending, handleAuthentication, tryToAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
