import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { WebSocketProvider } from "./context/WSContext";
import { AuthProvider } from "./context/AuthContext";
import App from "./App";
import { SceneProvider } from "./context/SceneContext";
import { GameProvider } from "./context/GameContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <WebSocketProvider>
    <SceneProvider>
      <GameProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </GameProvider>
    </SceneProvider>
  </WebSocketProvider>
  // </React.StrictMode>
);

reportWebVitals();
