import React, { createContext } from "react";
import { SceneContext } from "./SceneContext";
import { WebSocketContext } from "./WSContext";

const GameContext = createContext();
export const useGameContext = () => React.useContext(GameContext);


export const useGameContextState = () => {
  const [leaderboard] = React.useContext(GameContext);
  return leaderboard;
};

function GameProvider({ children }) {
  const [isEmailSubmitted, setIsEmailSubmitted] = React.useState(false);
  const [GamesScore, setScore] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const { fetchConfig } = React.useContext(SceneContext);
  const [isScene, setIsScene] = React.useState(true);
  const [isDndGame, setIsDnDGame] = React.useState(false);
  const [player, setPlayer] = React.useState(null)
  const { isConnected, subscribe, unsubscribe } = React.useContext(WebSocketContext);
  const [showLeaderBoard, setShowLeaderBoard] = React.useState(false);
  const [leaderboard, setLeaderboard] = React.useState(null);
  const { scene, quiz, loadingScreen } = React.useContext(SceneContext)
  
  React.useEffect(() => {
    refreshGameContext?.()
    setIsScene(true);
    setIsDnDGame(false);
    handleStorage({
      isScene: true,
      isDndGame: false
    });
  }, [scene, quiz, loadingScreen])

  React.useEffect(() => {
    refreshGameContext?.();
  }, [])

  React.useEffect(() => {
    if (isConnected) {
      subscribe?.("stop-dnd-game", (data) => {
        refreshGameContext?.()
        setIsScene(true);
        setIsDnDGame(false);
        handleStorage({
          isScene: true,
          isDndGame: false
        });
      });
      subscribe?.("dnd-game", (data) => {
        refreshGameContext?.()
        setIsDnDGame(true);
        setIsScene(false);
        handleStorage({
          isScene: false,
          isDndGame: true
        });
      });

      subscribe?.("show-leaderboard", (data) => {
        setShowLeaderBoard(true);
        setLeaderboard(data?.data)
      });

      subscribe?.("hide-leaderboard", (data) => {
        console.log("hide-leaderboard");

        setShowLeaderBoard(false);
        setLeaderboard(null)
      });

    } else {
      unsubscribe?.("dnd-game");
      unsubscribe?.("updateScene");
    }
  }, [isConnected]);
  console.log("leaderboard");
  console.log(leaderboard);

  React.useEffect(() => {
    const storedEmail = localStorage.getItem("@PlayerEmail");
    const storedScore = localStorage.getItem("@score");
    const storedPlayer = localStorage.getItem('@Player')
    if (storedScore) {
      setScore(Number(storedScore));
    }
    if (!storedEmail) {
      setIsEmailSubmitted(true);
    }
    if (storedPlayer) {
      setPlayer(JSON.parse(storedPlayer))
    }
  }, []);

  const updateMail = React.useCallback((payload) => {
    console.log(payload.email);

    if (payload) {
      setEmail(payload.email);
      localStorage.setItem("@PlayerEmail", payload.email);
      localStorage.setItem("@Player", JSON.stringify(payload));
      setPlayer(payload)
      setIsEmailSubmitted(false);
      fetchConfig?.();
    }
  }, []);

  const updateScore = React.useCallback((score) => {
    setScore(score);
    localStorage.setItem("@score", score);
  }, []);

  const getGamesScore = React.useCallback(() => {
    const storedScore = localStorage.getItem("@score");
    if (storedScore) {
      setScore(Number(storedScore));
    }
    return storedScore;
  }, []);

  const handleStorage = React.useCallback((payload) => {
    localStorage.setItem("@isScene", JSON.stringify(payload.isScene))
    localStorage.setItem("@isDndGame", JSON.stringify(payload.isDndGame))
  }, [])

  const refreshGameContext = React.useCallback(() => {
    const storedEmail = localStorage.getItem("@PlayerEmail");
    if (storedEmail) {
      setEmail(storedEmail)
    }
    const storedScore = localStorage.getItem("@score");
    if (storedScore) {
      setScore(Number(storedScore));
    }
    const StoredIsScene = localStorage.getItem("@isScene");
    if (StoredIsScene) {
      setIsScene(JSON.parse(StoredIsScene))
    }
    const StoredIsDndGame = localStorage.getItem("@isDndGame");
    if (StoredIsDndGame) {
      setIsDnDGame(JSON.parse(StoredIsDndGame))
    }
    const storedPlayer = localStorage.getItem('@Player')
    if (storedPlayer) {
      setPlayer(JSON.parse(storedPlayer))
    }
  }, [])

  const updatePlayer = React.useCallback((p) => {
    setPlayer(p)
    localStorage.setItem("@Player", JSON.stringify(p));

  }, [])

  return (
    <GameContext.Provider
      value={{
        isEmailSubmitted,
        email,
        updateMail,
        updateScore,
        GamesScore,
        getGamesScore,
        isScene,
        isDndGame,
        refreshGameContext,
        player,
        leaderboard,
        showLeaderBoard,
        updatePlayer
      }}
    >
      {children}
    </GameContext.Provider>
  );
}

export { GameContext, GameProvider };
