let SERVER_BASE_URL = "http://localhost:8080";
let SERVER_BASE_API_URL = "http://localhost:8080/api/";
let WS_SERVER_BASE_URL = "http://localhost:8080/ws";


if (process.env.NODE_ENV == "production") {
  SERVER_BASE_URL = "https://app.hannibal-ar.umactivation.com";
  SERVER_BASE_API_URL = "https://app.hannibal-ar.umactivation.com/api/";
  WS_SERVER_BASE_URL = "https://app.hannibal-ar.umactivation.com/ws";
}

export { SERVER_BASE_API_URL, WS_SERVER_BASE_URL, SERVER_BASE_URL };
