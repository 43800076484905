import React, { createContext } from "react";
import useScenes from "../hooks/useScenes";
import { WebSocketContext } from "./WSContext";

const SceneContext = createContext();

function SceneProvider({ children }) {
    const { fetchConfig, handleChangeScene, quiz, scene, loadingScreen, updateLoading, updateQuiz, updateScene, WS_SOCKET, handleLoadingScreen } = useScenes();
    const { isConnected, subscribe, unsubscribe } = React.useContext(WebSocketContext);

    React.useEffect(() => {
        if (isConnected) {
            subscribe?.("updateScene", (data) => {
                updateScene(data.scene);
            });

            subscribe?.("updateQuiz", (data) => {
                updateQuiz(data.quiz);
            });
            subscribe?.("loadingScreen", (data) => {
                updateLoading(data.loadingScreenState);
            });
        } else {
            unsubscribe?.("updateScene");
            unsubscribe?.("updateQuiz");
            unsubscribe?.("loadingScreen");
        }
    }, [isConnected]);


    const handleStartQuiz = React.useCallback(
        (sceneNumber) => {
            if (WS_SOCKET && scene === sceneNumber) {
                const newQuizState = !quiz;
                WS_SOCKET.send(JSON.stringify({ type: "startQuiz", quiz: newQuizState }));
                updateQuiz(newQuizState);
            } else {
                alert("Quiz can only be started in the current scene.");
            }
        },
        [WS_SOCKET, scene, quiz, quiz]
    );

    return (
        <SceneContext.Provider value={{ quiz, scene, fetchConfig, handleChangeScene, loadingScreen, handleStartQuiz, handleLoadingScreen}}>
            {children}
        </SceneContext.Provider>
    )
}

export { SceneContext, SceneProvider };
