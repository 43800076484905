import React from "react";
import { SERVER_BASE_API_URL } from "../config/serverConfig";

const useServer = () => {
  const fetchCurrentScene = React.useCallback(async () => {
    try {
      const response = await fetch(`${SERVER_BASE_API_URL}currentScene`, {
        headers: { "ngrok-skip-browser-warning": "true" },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Error fetching current scene:", err);
    }
    return null;
  }, []);

  const fetchConfig = React.useCallback(async () => {
    try {
      const res = await fetch(SERVER_BASE_API_URL + "config");
      const config = await res.json();
      return config;
    } catch (error) {
      console.error("Failed to fetch config:", error);
    }
    return null;
  }, []);

  const changeScene = React.useCallback(async (sceneNumber) => {
    try {
      const response = await fetch(SERVER_BASE_API_URL + "config/scene", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ scene: sceneNumber }),
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.error("Failed to update scene:", err);
    }
    return null;
  }, []);

  const updateClientScore = React.useCallback(async (payload) => {
    try {
      const response = await fetch(SERVER_BASE_API_URL + "submit-answers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    fetchCurrentScene,
    fetchConfig,
    changeScene,
    updateClientScore
    ,
  };
};

export default useServer;
