import React, { lazy, Suspense } from "react";
import { PATH_APP } from "./paths";
import { useRoutes } from "react-router-dom";
import CustomLoadingOverlay from "../components/custom-overlay/CustomLoadingOverlay";
import AuthGuard from "../guards/AuthGuard";
import SceneGuard from "../guards/SceneGuard";
import DndGameGuard from "../guards/DndGameGuard";

const Loadable = (Component) => (props) => {

    return (
        <Suspense fallback={<CustomLoadingOverlay />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function AppRoutes() {

    return useRoutes([
        {
            path: PATH_APP.general.admin,
            element: (
                <AuthGuard>
                    <AdminPage />
                </AuthGuard>
            )
        },
        {
            path: PATH_APP.general.landing,
            index: true,
            element: (
                <SceneGuard>
                    <Landing />
                </SceneGuard>
            )
        },
        {
            path: PATH_APP.general.leaderboard,
            element: (
                <LeaderBoardPage />
            )
        },
        {
            path: PATH_APP.general.dnd,
            index: true,
            element: (
                <DndGameGuard>
                    <DndGame />
                </DndGameGuard>
            )
        },
        {
            path: "*",
            element: (
                <Page404 />
            )
        }
    ])
};


const Landing = Loadable(lazy(() => import('../pages/home/HomePage')));
const AdminPage = Loadable(lazy(() => import('../pages/dashboard/AdminPage')));
const DndGame = Loadable(lazy(() => import('../pages/games/Dnd-game')));
const LeaderBoardPage = Loadable(lazy(() => import('../pages/leaderboard/LeaderBoardPage')));
const Page404 = Loadable(lazy(() => import('../pages/errors/Page404')));