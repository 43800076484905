import React from "react";
import { AuthContext } from "../context/AuthContext";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, tryToAuth } = React.useContext(AuthContext);
  if (isAuthenticated) {
    return children;
  } else {
    setTimeout(() => {
      tryToAuth?.();
    }, 200);
  }
};

export default AuthGuard;
