import loader from "../../assets/loader/gol.webp";
import loaderGif from "../../assets/loader/loading.gif";



const CustomLoadingOverlay = ({ isVisible }) => {
  return (
    <>
      {isVisible ?
        <div
          className="hexagon"
          aria-label="Animated hexagonal ripples"
          style={{
            visibility: isVisible ? "visible" : "none",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10000,
            backgroundColor: "black",
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div className="hexagon__group">
            <div className="hexagon__sector"></div>
            <div className="hexagon__sector"></div>
            <div className="hexagon__sector"></div>
            <div className="hexagon__sector"></div>
            <div className="hexagon__sector"></div>
            <div className="hexagon__sector"></div>
          </div>
          <div id="Loading">
          <div className="refresh-button-container" onClick={()=>{window.location.reload();}} style={{position:'fixed',top:'15px',left:'15px',backgroundColor:'#dbb900',padding:'10px',borderRadius:'20px'}}>
        <i className="fas fa-sync-alt refresh-icon reload" style={{fontSize:'35px'}} ></i>
      </div>
            <img alt="Hannibal Statu" src={loader} style={{     height:'100vh'}} />
            <img src={loaderGif} style={{position:'fixed',width:'100px',top:'80%',left:'35%',transform:'transform: translate(-50%, -50%);'}} />
            {/* <p id="Loading-word" >
              Loading
            </p> */}
          </div>
        </div>
        : null}
    </>
  );
};

export default CustomLoadingOverlay;