import React from "react";
import { WebSocketContext } from "../context/WSContext";
import useServer from "./useServer";


const useScenes = () => {
    const { isConnected, ws } = React.useContext(WebSocketContext);
    const [scene, setScene] = React.useState(null);
    const [quiz, setQuiz] = React.useState(false);
    const [loadingScreen, setLoadingScreen] = React.useState(false);

    const { fetchConfig, changeScene } = useServer();

    const WS_SOCKET = React.useMemo(() => {
        if (isConnected && ws && ws.current) return ws.current;
        return null;
    }, [ws, isConnected]);

    const handleLoadingScreen = React.useCallback(() => {
        if (!isConnected || !WS_SOCKET) return;
        const newLoadingState = !loadingScreen;
        setLoadingScreen(newLoadingState);
        if (WS_SOCKET) {
            WS_SOCKET.send(
                JSON.stringify({
                    type: "loadingScreen",
                    loadingScreenState: newLoadingState,
                })
            );
        }
    }, [loadingScreen, WS_SOCKET, isConnected]);

    const fetchConfig_ = async () => {
        try {
            const res = await fetchConfig?.();
            if (res) {
                console.log("res");
                console.log(res);
                
                setScene(res.currentScene);
                setQuiz(res.quizStart);
                setLoadingScreen(res.loadingScreenState);
            }
        } catch (error) {
            console.error("Failed to fetch config:", error);
        }
    };

    React.useEffect(() => {
        fetchConfig_();
    }, []);

    const handleChangeScene = React.useCallback(
        async (sceneNumber) => {

            if (quiz) {
                alert("Cannot change scene while a quiz is open.");
                return;
            }
            try {
                const response = await changeScene(sceneNumber);
                if (response.success) {
                    updateScene(response.scene)
                    if (WS_SOCKET) {
                        WS_SOCKET.send(
                            JSON.stringify({ type: "changeScene", scene: sceneNumber })
                        );
                    }
                }
            } catch (error) {
                console.error("Error sending scene change to server:", error);
            }
        },
        [WS_SOCKET, quiz]
    );

    const updateScene = React.useCallback((data) => {
        setScene(data)
    }, [])

    const updateQuiz = React.useCallback((data) => {
        setQuiz(data)
    }, [])

    const updateLoading = React.useCallback((data) => {
        setLoadingScreen(data)
    }, [])

    return {
        handleChangeScene,
        fetchConfig,
        updateScene,
        updateQuiz,
        updateLoading,
        quiz,
        scene,
        loadingScreen,
        WS_SOCKET,
        handleLoadingScreen
    }
}

export default useScenes;