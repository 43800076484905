import React, { createContext, useEffect, useRef } from "react";
import { WS_SERVER_BASE_URL } from "../config/serverConfig";

const WebSocketContext = createContext();

function WebSocketProvider({ children }) {
  const ws = useRef(null);
  const channels = useRef({});
  const [isConnected, setIsConnected] = React.useState(false);

  const subscribe = (channel, callback) => {
    channels.current[channel] = callback;
  };

  const unsubscribe = (channel) => {
    delete channels.current[channel];
  };

  const handleSocketConnection = React.useCallback(() => {
    ws.current = new WebSocket(WS_SERVER_BASE_URL);
    ws.current.onopen = () => {
      setIsConnected(true);
      console.log("connect");

    };

    ws.current.onclose = () => {
      setIsConnected(false);
      console.log("disconnect");
      if (!isConnected)
        setTimeout(handleSocketConnection, 500)
    };

    ws.current.onmessage = (message) => {
      const { type, ...data } = JSON.parse(message.data);
      if (channels.current[type]) {
        channels.current[type](data);
      } else {
        channels.current[type]?.(data);
      }
    };

  }, [ws, isConnected])

  useEffect(() => {
    if (!isConnected) {
      handleSocketConnection()
    }
  }, [isConnected, ws]);

  return (
    <WebSocketContext.Provider
      value={{ ws, channels, isConnected, subscribe, unsubscribe }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export { WebSocketContext, WebSocketProvider };
