import './App.css';
import {
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import AppRoutes from "./routes";
import { WebSocketContext } from './context/WSContext';
import React from 'react';
import { GameContext } from './context/GameContext';
import CustomLoadingOverlay from './components/custom-overlay/CustomLoadingOverlay';
import { SceneContext } from './context/SceneContext';

function App() {
  const { isConnected, subscribe, unsubscribe } = React.useContext(WebSocketContext);
  const { player, isDndGame, isScene } = React.useContext(GameContext);
  
  React.useEffect(() => {
    if (isConnected) {
      subscribe?.("reload-screen", (data) => {
        console.log("reloading");
        if (player && isDndGame) {
          window.location.reload()
        }
      });
    } else {
      console.log("herrrr");

      unsubscribe?.("reload");
    }
  }, [isConnected, player])

  return (<>
    {!isConnected && <CustomLoadingOverlay isVisible={!isConnected} />}
    <Router>
      <AppRoutes />
    </Router >
  </>

  )
}

export default App;
