
function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_APP = '';

export const PATH_APP = {
    root: ROOTS_APP,
    general: {
        landing: path(ROOTS_APP, '/'),
        admin: path(ROOTS_APP, '/admin'),
        dnd: path(ROOTS_APP, '/dnd-game'),
        leaderboard: path(ROOTS_APP, '/leaderboard'),
    },
};
