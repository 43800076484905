import React from "react";
import { GameContext } from "../context/GameContext";
import { Navigate } from "react-router-dom";
import { PATH_APP } from "../routes/paths";

const DndGameGuard = ({ children }) => {
    const { isDndGame, refreshGameContext } = React.useContext(GameContext);

    if (isDndGame) {
        return children;
    } else {
        return <Navigate to={PATH_APP.general.landing} replace />
    }
};

export default DndGameGuard;
